import { faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import { Button, Message, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiHcp } from '../../../api/hcp';
import { Link } from 'react-router-dom';

const HcpListView: FC = (): JSX.Element => {
    const fileInputRef: RefObject<HTMLInputElement> = createRef();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ hcps, setHcps ] = useState<ApiHcp[]>([]);
    const [ errors, setErrors ] = useState<any>();

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listHcps({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setHcps(data.data);
            setIsLoading(false);
        });
    }, []);
    useEffect(() => fetch(), [fetch]);

    const handleFileUpload = (e: any): void => {
        setErrors(undefined);

        api.importHcps(e.target.files[0]).then((res) => {
            toast('Import uploaded succesfully, it will run in the background.');
        }).catch(({ response }) => {
            console.log(response.data.errors);
            if (response.data.errors.file) {
                toast('Invalid file format, upload CSV or Excel', 'error');
            } else {
                toast('Errors found in file', 'error');
                setErrors(response.data.errors);
            }
        });
    }

    return (<>
        <PageHeader
            title="Hcps"
            breadcrumb={{
                '/admin/settings': 'Settings',
                '/admin/hcps': 'Hcps'
            }}
        >
            <input
                accept=".csv, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileUpload}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
            />
            <Button
                icon={faUpload}
                label="Import"
                onClick={() => fileInputRef.current?.click()}
            />
        </PageHeader>

        {errors ? Object.keys(errors).map((line: any, index) => (
            <Message
                key={`err-${index}`}
                error
                title={`Error on line ${line}`}
            >
                <ul>
                    {errors[line].map((o: string, i: number) => <li key={`err-${index}-${i}`}>{o}</li>)}
                </ul>
            </Message>
        )) : (<>
            <Segment isLoading={isLoading}>
                <Table.Actions
                    onSearch={fetch}
                />
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell collapsing>
                                Registration no.
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Name
                            </Table.HeaderCell>
                        </Table.Row>
                    </thead>
                    <tbody>
                        {hcps.length > 0 ? hcps.map((hcp) => (
                            <Table.Row key={`row-${hcp.id}`}>
                                <Table.Cell primary name="Registration no." collapsing>
                                    <Link to={`/admin/hcps/${hcp.id}/limits`}>
                                        {hcp.nationalid}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell name="Name">
                                    {hcp.name}
                                </Table.Cell>
                            </Table.Row>
                        )) : (
                            <Table.Row noResults />
                        )}
                    </tbody>
                </Table>
            </Segment>
            {meta && <Table.Pagination
                meta={meta}
                onChange={(page: number) => fetch('', page)}
            />}
        </>)}
    </>);
}

export default HcpListView;
