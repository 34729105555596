import React, { HTMLAttributes } from 'react';
import GridColumn from './Column';
import GridRow from './Row';
import styles from './styles.module.scss';

interface GridProps extends HTMLAttributes<HTMLDivElement> {
}

class Grid extends React.Component<GridProps> {
    static Column = GridColumn;
    static Row = GridRow;

    render = (): JSX.Element => {
        const { children, className, ...rest } = this.props;

        return (
            <div className={[styles.base, className || ''].join(' ')} {...rest}>
                {children}
            </div>
        );
    }
}

export default Grid;
