import axios from 'axios';
import alert from './alert';
import auth from './auth';
import hcp from './hcp';
import hospital from './hospital';
import order from './order';
import product from './product';
import user from './user';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    hcpLogin: auth.hcpLogin,
    logout: auth.logout,
    
    deleteHospital: hospital.delete,
    getHospital: hospital.get,
    listHospitals: hospital.list,
    putHospital: hospital.put,
    importHospitals: hospital.uploadImport,

    deleteAlert: alert.delete,
    listAlerts: alert.list,
    getAlert: alert.get,
    putAlert: alert.put,
    getActiveAlert: alert.getActive,

    checkProductLimits: order.checkLimits,
    deleteOrder: order.delete,
    listOrders: order.list,
    getOrder: order.get,
    putOrder: order.put,
    uploadSignature: order.uploadSignature,
    
    autocompleteProducts: product.autocomplete,
    deleteProduct: product.delete,
    getProduct: product.get,
    listProducts: product.list,
    putProduct: product.put,
    putProductFile: product.putFile,
    
    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,

    getHcp: hcp.get,
    listHcps: hcp.list,
    importHcps: hcp.uploadImport,
};

export default api;
