import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { Button, Checkbox, Form, Modal, PageHeader, Segment } from '../../RbKit';
import styles from './styles.module.scss';

interface DisclaimerProps {
    onUpdate: (n: number) => void,
}

const Disclaimer: FC<DisclaimerProps> = ({ onUpdate }): JSX.Element => {
    const [ agree, setAgree ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ step, setStep ] = useState<number>(0);
    
    useEffect(() => {
        onUpdate(step);
    }, [step]); // eslint-disable-line

    return (<div className="container-small">
        <PageHeader
            title="Please fill out this form to order samples"
            breadcrumb={{
                '/orders': 'Orders',
                '/orders/create/disclaimer': 'Disclaimer',
            }}
        />
        <div className={styles.wizard}>
            <div>
                <div
                    className={`${styles.step} ${step >= 1 ? styles.active : ''} ${step >= 2 ? styles.done : ''}`}
                    onClick={() => setStep(step > 1 ? 1 : step)}
                >
                    <div>1</div>
                    <span>Reimbursement</span>
                </div>
            </div>
            <div>
                <div
                    className={`${styles.step} ${step >= 2 ? styles.active : ''} ${step >= 3 ? styles.done : ''}`}
                    onClick={() => setStep(step > 2 ? 2 : step)}
                >
                    <div>2</div>
                    <span>Clinical trial</span>
                </div>
            </div>
            <div>
                <div
                    className={`${styles.step} ${step >= 3 ? styles.active : ''} ${step >= 4 ? styles.done : ''}`}
                    onClick={() => setStep(step > 3 ? 3 : step)}
                >
                    <div>3</div>
                    <span>CUP/MNP</span>
                </div>
            </div>
            <div>
                <div className={`${styles.step} ${step >= 4 ? styles.active : ''}`}>
                    <div>4</div>
                    <span>Samples</span>
                </div>
            </div>
        </div>

        <Form>
            {step === 0 && (
                <Segment className={styles.disclaimer}>
                    <h2 style={{ marginBottom: 5 }}>Welcome</h2>
                    <p>Are you a physician who is looking for access to a Roche medicinal product for one of your patients?</p>
                    <div className={styles.buttons}>
                        <div>
                            <Button
                                label="Yes"
                                onClick={() => setStep(1)}
                                primary
                            />
                            <p>
                                YES – Great, you are in the right place.
                            </p>
                        </div>
                        <div>
                            <Button
                                label="No"
                                onClick={() => setShowModal(true)}
                            />
                            <p>
                                NO – Alas, seems you got lost on RochePro, let me redirect you to the homepage.
                            </p>
                        </div>
                    </div>
                </Segment>      
            )}
            {step === 1 && (
                <Segment className={styles.disclaimer}>
                    <h2 style={{ marginBottom: 5 }}>Reimbursement</h2>
                    <p>
                        <b>Have you verified whether the Roche molecule or a satisfactory alternative is accessible through reimbursement?</b><br />
                        You can consult the official reimbursement criteria via <a href="https://ondpanon.riziv.fgov.be/SSPWebApplicationPublic/nl/Public/ProductSearch">https://ondpanon.riziv.fgov.be/SSPWebApplicationPublic/nl/Public/ProductSearch</a>
                    </p>
                    <div className={styles.buttons}>
                        <div>
                            <Button
                                label="Yes"
                                onClick={() => setStep(2)}
                                primary
                            />
                            <p>
                                YES - I have verified this and unfortunately the molecule is not reimbursed for my intended Use or the molecule is reimbursed but my patient is not covered by Belgian health insurance.
                            </p>
                        </div>
                        <div>
                            <Button
                                label="No"
                                onClick={() => setShowModal(true)}
                            />
                            <p>
                                NO – Please check whether your patient meets the reimbursement criteria of the Roche molecule or a satisfactory alternative, before proceeding.
                            </p>
                        </div>
                    </div>
                </Segment>      
            )}
            {step === 2 && (
                <Segment className={styles.disclaimer}>
                    <h2 style={{ marginBottom: 5 }}>Clinical trial</h2>
                    <p>
                        <b>Is there an ongoing clinical trial for which your patient could be eligible?</b><br />
                        All privately and publicly funded trials conducted around the globe can be consulted via <a href="https://clinicaltrials.gov/">https://clinicaltrials.gov/</a><br />
                        All oncology trials conducted in Belgium can be consulted via <a href="http://www.cancertrials.be/">http://www.cancertrials.be/</a><br />
                        All Roche trials conducted in Belgium can be consulted via <a href="https://forpatients.roche.com/">https://forpatients.roche.com/</a>
                    </p>
                    <div className={styles.buttons}>
                        <div>
                            <Button
                                label="Yes"
                                primary
                                onClick={() => setShowModal(true)}
                            />
                            <p>
                                YES - Please refer your patient to the ongoing clinical trial.
                            </p>
                        </div>
                        <div>
                            <Button
                                label="No"
                                onClick={() => setStep(3)}
                            />
                            <p>
                                NO - I have explored the possibility for inclusion into a clinical trial but no appropriate trials are ongoing or my patient is ineligible for appropriate ongoing trials.
                            </p>
                        </div>
                    </div>
                </Segment>      
            )}
            {step === 3 && (
                <Segment className={styles.disclaimer}>
                    <h2 style={{ marginBottom: 5 }}>CUP/MNP</h2>
                    <p>
                        <b>Is there an ongoing Compassionate Use or Medical Need program for which your patient could be eligible?</b><br />
                        All ongoing programs in Belgium can be consulted via:<br />
                        <a href="https://www.famhp.be/en/human_use/medicines/medicines/research_development/ compassionate_use_medical_need">https://www.famhp.be/en/human_use/medicines/medicines/research_development/ compassionate_use_medical_need</a>
                    </p>
                    <div className={styles.buttons}>
                        <div>
                            <Button
                                label="Yes"
                                primary
                                onClick={() => setShowModal(true)}
                            />
                            <p>
                                YES – Please refer your patient to the ongoing CUP/MNP.
                            </p>
                        </div>
                        <div>
                            <Button
                                label="No"
                                onClick={() => setStep(4)}
                            />
                            <p>
                                NO - I have checked the website above and I hereby declare that my patient is not eligible for any Compassionate Use or Medical Need program.
                            </p>
                        </div>
                    </div>
                </Segment>      
            )}
            {step === 4 && (
                <Segment className={styles.disclaimer}>
                    <h2 style={{ marginBottom: 5 }}>Samples</h2>
                    <p style={{ textAlign: 'left' }}>
                        It seems your patient does not have access to the envisioned Roche medicinal product, nor a satisfactory alternative, through reimbursement, a clinical trial or a Compassionate Use or Medical Need Program. The Roche product could possibly be made available under the form of samples, upon your specific request, to remedy urgent circumstances, a medical necessity or a social need (according to the R.D. 11 Jan 1993).<br /><br />
                        At Roche we are committed to bringing better outcomes to more patients faster. Providing access to many of our innovative products under the form of medicinal samples -to remedy the above specified situations- is therefore an important social responsibility that we want to take as a company.<br /><br />
                        However, we need your help -as a medical professional- to keep the provision of samples sustainable, by strictly limiting the use of samples to the legally defined conditions (urgent circumstances, medical necessity and/or social need) and by taking genuine, well-informed, thought-through therapeutic decisions to make sure that we can continue to fulfil this social responsibility<br /><br />
                        Roche reserves the right to stop the provision of samples of any product at any time. Off-label use and adverse events should be reported to or directly to the Federal Agency for Medicines and Health Products via www.famhp.be/en report_a_side_effect_as_a_health_care_professional.
                    </p>
                    <div className={styles.checkbox}>
                        <Checkbox
                            checked={agree}
                            onChange={({ checked }: any) => setAgree(checked)}
                            label="I acknowledge that I have read Roche's vision on samples and that I understand my responsibilities."
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => setStep(5)}
                            primary
                            disabled={!agree}
                            label="Continue"
                        />
                    </div>
                </Segment>      
            )}
        </Form>
        <Modal
            size="small"
            open={showModal}
            header="It seems like you got lost"
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        link
                        onClick={() => setShowModal(false)}
                        label="Go back"
                    />
                    <Button
                        primary
                        label="Redirect"
                        href="https://rochepro.be"
                        target="_blank"
                    />
                </div>
            )}
        >
            <p>
                It seems like you got lost at RochePro. Shall we redirect you to RochePro or would you like to go back to the last step?
            </p>
        </Modal>
    </div>);
}

export default Disclaimer;
