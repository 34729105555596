import React, { FC, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faFilter } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../elements/Button';
import Input from '../../elements/Input';
import Segment from '../../elements/Segment';
import { useDebounceSearch, useHandleKeyUp } from '../../lib/hooks';
import styles from './styles.module.scss';

interface TableActionsProps {
    autoLoad?: boolean,
    autoSearch?: boolean,
    filter?: JSX.Element,
    onFilter?: (searchQuery: string) => void,
    onSearch?: (query: string) => void,
    remember?: boolean,
}

const TableActions: FC<TableActionsProps> = ({
    autoLoad,
    autoSearch,
    filter,
    onFilter,
    onSearch,
    remember
}): JSX.Element => {
    const [ showFilter, setShowFilter ] = useState(false);
    const [ searchQuery, setSearchQuery ] = useState('');
    const debounceSearch = useDebounceSearch(onSearch);
    
    const handleSearch = useCallback((value: string, update: boolean = false) => {
        localStorage.setItem(`apu-filter-${window.location.href}`, value);
        setSearchQuery(value);
        if (autoSearch || update) debounceSearch(value);
    }, [debounceSearch, autoSearch]);

    useHandleKeyUp(() => {
        if (searchQuery !== '') {
            handleSearch('', true);
        }
        setShowFilter(false);
    }, [27]);

    useHandleKeyUp(() => {
        debounceSearch(searchQuery);
    }, [13]);

    const handleFilter = () => {
        setShowFilter(false);
        if (onFilter) {
            onFilter(searchQuery);
        }   
    }

    useEffect(() => {
        const cache = localStorage.getItem(`apu-filter-${window.location.href}`);
        if (autoLoad) handleSearch(remember ? (cache || '') : '', true);

        window.addEventListener('click', () => setShowFilter(false));
        return () => window.removeEventListener('click', () => setShowFilter(false));
    }, [handleSearch, remember, autoLoad]);

    return (
        <div className={styles.actionsContainer}>
            {onSearch && (
                <div className={styles.searchContainer}>
                    <Input
                        icon={(
                            <FontAwesomeIcon
                                icon={searchQuery === '' ? faSearch : faTimes}
                                onClick={searchQuery === '' ? undefined : () => handleSearch('', true)}
                                style={searchQuery === '' ? undefined : { cursor: 'pointer' }}
                            />
                        )}
                        onChange={({ value }: any) => handleSearch(value)}
                        placeholder="Search"
                        transparent
                        value={searchQuery}
                    />
                </div>
            )}
            {filter && (
                <div
                    className={styles.filterContainer}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Button
                        icon={faFilter}
                        onClick={() => setShowFilter(!showFilter)}
                        trigger
                        triggered={showFilter}
                    />
                    <Segment
                        className={styles.filterInnerContainer}
                        padding="compact"
                        style={{ display: showFilter ? 'block' : 'none' }}
                    >
                        {filter}
                        <div className={styles.filterButtons}>
                            <Button
                                label="Annuleren"
                                link
                                onClick={() => setShowFilter(false)}
                            />
                            <Button
                                label="Filter"
                                primary
                                onClick={() => handleFilter()}
                            />
                        </div>
                    </Segment>
                </div>
            )}
        </div>
    );
}

export default TableActions;
