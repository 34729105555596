import { createStore, combineReducers } from 'redux';
import auth, { AuthState } from './reducers/auth';

export interface AppState {
    auth: AuthState,
}

export default createStore(combineReducers({
    auth,
}));
