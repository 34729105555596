import { faFilePdf, faPlus, faTrashAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useEffect, useState, RefObject, createRef } from 'react';
import { Link } from 'react-router-dom';
import api, { getToken, ApiMeta } from '../../api';
import { ApiOrder } from '../../api/order';
import { useProfile } from '../../lib/auth';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../RbKit';
import Tag from '../../RbKit/elements/Tag';
import styles from './styles.module.scss';

const OrderListView: FC = (): JSX.Element => {
    const fileInputRef: RefObject<HTMLInputElement> = createRef();
    const [ active, setActive ] = useState<string>();
    const [ orders, setOrders ] = useState<ApiOrder[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ upload, setUpload ] = useState<string>();
    const profile = useProfile();

    const fetch = (query?: string, page: number = 1) => {
        api.listOrders({ query, page }).then(({ data }) => {
            setOrders(data.data);
            setMeta(data.meta);
        });
    }

    useEffect(fetch, []);

    const deleteOrder = (orderId: string): void => {
        api.deleteOrder(orderId).then(() => {
            fetch();
            toast('Order cancelled successfully');
        });
    }

    const startFileUpload = (orderId: string): void => {
        setUpload(orderId);
        fileInputRef.current?.click();
    }

    const handleFileUpload = (e: any): void => {
        if (!upload) return;
        api.uploadSignature(upload, e.target.files[0]).then(() => {
            setUpload(undefined);
            toast('Document uploaded succesfully');
            fetch();
        }).catch(() => {
            window.alert('Please upload a PDF file');
        });
    }

    return (<>
        <input
            accept="application/pdf"
            onChange={handleFileUpload}
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
        />
        <PageHeader title="Orders" breadcrumb={{'/admin/users': 'Overview'}}>
            {profile?.role === 'Administrator' ? (
                <Button
                    label="Export"
                    padding="compact"
                    primary
                    href={`${process.env.REACT_APP_API_BASE_URL}admin/orders/export?_token=${getToken()}`}
                    target="_blank"
                />
            ) : (
                <Button
                    href="/orders/create"
                    icon={faPlus}
                    padding="compact"
                    primary
                />
            )}
        </PageHeader>
        <Segment>
            <Table.Actions onSearch={fetch} />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing>
                            Number
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Amount
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Hospital
                        </Table.HeaderCell>
                        {profile?.role === 'Administrator' && (<>
                            <Table.HeaderCell>
                                HCP
                            </Table.HeaderCell>
                        </>)}
                        <Table.HeaderCell>
                            Status
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {orders.map((order) => (<>
                        <Table.Row
                            key={order.id}
                            // className={active === order.id ? styles.active : ''}
                        >
                            <Table.Cell collapsing primary name="Number">
                                <Link to={`/orders/${order.id}/edit`}>
                                    #{order.number}
                                </Link>
                            </Table.Cell>
                            <Table.Cell name="Date">
                                {order.createdAt}
                            </Table.Cell>
                            <Table.Cell name="Amount">
                                {order.products?.length} product{order.products?.length === 1 ? '' : 's'}
                            </Table.Cell>
                            <Table.Cell collapsing name="Hospital">
                                {order.hospital}
                            </Table.Cell>
                            {profile?.role === 'Administrator' && (<>
                                <Table.Cell collapsing name="HCP">
                                    {order.hcp?.name}
                                </Table.Cell>
                            </>)}
                            <Table.Cell collapsing name="Amount">
                                <Tag
                                    success={order.status === 2}
                                    warning={order.status === 1}
                                    label={(order.statusName === 'Needs signature' ? 'New' : (order.statusName || 'New')).toUpperCase()}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {order.needsSignature && profile?.role !== 'Administrator' ? (
                                    <Button
                                        icon={faUpload}
                                        onClick={() => startFileUpload(order.id)}
                                    />
                                ) : (
                                    <Confirm
                                        content="Are you sure you wish to upload a new document? this action will replace any previously uploaded document"
                                        onConfirm={() => startFileUpload(order.id)}
                                        trigger={<Button
                                            icon={faUpload}
                                        />}
                                    />
                                )}
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Button
                                    trigger
                                    icon={active === order.id ? faCaretDown : faCaretRight}
                                    onClick={() => setActive(order.id)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    {order.signedAt && (<>
                                        <Menu.Item
                                            content="Download signed form"
                                            icon={faFilePdf}
                                        >
                                            <a
                                                href={`${process.env.REACT_APP_API_BASE_URL}orders/${order.id}/download?_token=${getToken()}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Download signed form
                                            </a>
                                        </Menu.Item>
                                    </>)}
                                    <Menu.Item
                                        content="Download form"
                                        icon={faFilePdf}
                                    >
                                        <a
                                            href={`${process.env.REACT_APP_API_BASE_URL}orders/${order.id}/download?_token=${getToken()}&original=1`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Download form
                                        </a>
                                    </Menu.Item>
                                    {profile?.role === 'Administrator' && (<>
                                        <Confirm
                                            content="Are you sure you wish to cancel this order? This action cannot be undone"
                                            onConfirm={() => deleteOrder(order.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt}
                                                content="Cancel order"
                                            />}
                                        />
                                    </>)}
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                        {order.products?.map((op, index) => (
                            <Table.Row
                                key={`${order.id}-${index}-op`}
                                className={`${styles.subrow} ${active !== order.id ? styles.hidden : ''}`}
                            >
                                <Table.Cell collapsing primary name="Number">
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell name="product">
                                    <b>{op.product.name} {op.product.form}</b><br />
                                    {op.product.link && op.product.link.includes('https:') && (
                                        <a
                                            href={op.product.link}
                                            rel="noopenerer noreferrer"
                                            target="_blank"
                                        >
                                            Product details &amp; dosage calc.
                                        </a>
                                    )}
                                </Table.Cell>
                                <Table.Cell name="Amount">
                                    <span>Amount</span>
                                    {op.amount}
                                </Table.Cell>
                                <Table.Cell collapsing name="Dosage">
                                    <span>Dosage</span>
                                    {op.productDosage.dosage}
                                </Table.Cell>
                                <Table.Cell
                                    collapsing
                                    name="Indications"
                                    className={styles.indications}
                                >
                                    <div>
                                        <span>Off-label</span>
                                        : {op.indication1 === true ? 'Yes' : 'No'}
                                    </div>
                                    {op.indication1 === true && (<>
                                        <div>
                                            <span>Prolongation</span>
                                            : {op.indication3 === true ? 'Yes' : (op.indication3 === false ? 'No' : <span>&nbsp;</span>)}
                                        </div>
                                        {(op.indication2 !== undefined || op.indication3 === true) && (
                                            <div>
                                                <span>Contact</span>
                                                : {op.indication2 === true ? 'Yes' : (op.indication2 === false ? 'No' : <span>&nbsp;</span>)}
                                            </div>
                                        )}
                                    </>)}
                                </Table.Cell>
                                {profile?.role === 'Administrator' && (<>
                                    <Table.Cell collapsing name="HCP">
                                        &nbsp;
                                    </Table.Cell>
                                    <Table.Cell collapsing name="Amount">
                                        &nbsp;
                                    </Table.Cell>
                                </>)}
                                <Table.Cell collapsing actions>
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell collapsing actions />
                            </Table.Row>
                        ))}
                    </>))}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default OrderListView;
