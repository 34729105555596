import React, { HTMLAttributes } from 'react';
import FormDropdown from './Dropdown';
import FormGroup from './Group';
import FormInput from './Input';
import FormTextarea from './Textarea';

interface FormProps extends HTMLAttributes<HTMLFormElement> {
}

class Form extends React.Component<FormProps> {
    static Dropdown = FormDropdown;
    static Group = FormGroup;
    static Input = FormInput;
    static Textarea = FormTextarea;

    render = (): JSX.Element => {
        const { children, ...rest } = this.props;

        return (
            <form {...rest}>
                {children}
            </form>
        );
    }
}

export default Form;
