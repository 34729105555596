import React, { ChangeEvent, FC, TextareaHTMLAttributes, isValidElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';

export interface TextareaChangeProps {
    name: string,
    value?: any,
}

export interface TextareaProps {
    error?: boolean | string,
    icon?: IconDefinition | JSX.Element,
    onChange?: (data: any, e: ChangeEvent<HTMLTextAreaElement>) => void,
    transparent?: boolean,
}

const Textarea: FC<TextareaProps & TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
    className,
    error,
    icon,
    onChange,
    transparent,
    value,
    ...props
}): JSX.Element => {
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value,
            }, e);
        }
    }

    return (
        <div
            className={[
                styles.base,
                transparent && styles.transparent,
            ].join(' ')}
        >
            {icon && (isValidElement(icon) ? icon : <FontAwesomeIcon icon={icon as IconDefinition} />)}
            <textarea
                className={[
                    styles.input,
                    error && styles.error,
                    icon && styles.withIcon,
                    className,
                ].join(' ')}
                onChange={handleChange}
                value={value || ''}
                {...props}
            />
        </div>
    )
}

export default Textarea;
