import { faPlus, faPencil, faTrashAlt, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiAlert } from '../../../api/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ alerts, setAlerts ] = useState<ApiAlert[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listAlerts({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setAlerts(data.data);
            setIsLoading(false);
        });
    }

    const deleteAlert = (alertId: number | string): void => {
        api.deleteAlert(alertId).then(() => {
            fetch();
            toast('Alert deleted successfully');
        });
    }

    return (<>
        <PageHeader
            title="Alerts"
            breadcrumb={{
                '/admin/settings': 'Settings',
                '/admin/alerts': 'Alerts'
            }}
        >
            <Button
                href="/admin/alerts/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="center">
                            Active
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {alerts.length > 0 ? alerts.map((alert) => (
                        <Table.Row key={`row-${alert.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/admin/alerts/${alert.id}/edit`}>
                                    {alert.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell name="Is active" collapsing align="center">
                                <FontAwesomeIcon
                                    icon={alert.isActive ? faCheck : faTimes}
                                    className={alert.isActive ? 'success' : 'error'}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/admin/alerts/${alert.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this alert? This action cannot be undone"
                                        onConfirm={() => deleteAlert(alert.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default AlertListView;
