import { faCog, faFileAlt, faPlus } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hasRole, useProfile } from '../../lib/auth';
import { Menu, Segment } from '../../RbKit';
import { useWindowResize } from '../../RbKit/lib/hooks';
import styles from './styles.module.scss';

export const collapse = (state?: boolean) => {
    dispatchEvent(new CustomEvent('toggle-drawer', { detail: { state }}));
}

export const useCollapse = () => {
    const [ isCollapsed, setIsCollapsed ] = useState(false);

    useEffect(() => {
        const handleCollapse = (e: any) => {
            if (e.detail && e.detail.state !== undefined) {
                setIsCollapsed(e.detail.state);
            } else {
                setIsCollapsed(!isCollapsed);
            }
        };
        window.addEventListener('toggle-drawer', handleCollapse);
        return () => window.removeEventListener('toggle-drawer', handleCollapse);
    });

    return isCollapsed;
}

const Drawer: FC = (): JSX.Element => {
    const isCollapsed = useCollapse();
    const location = useLocation();
    const profile = useProfile();
    const windowCollapse = () => collapse(window.innerWidth <= 992);
    useWindowResize(windowCollapse, 100);
    useEffect(windowCollapse, []);
    
    return (
        <Segment
            className={[
                styles.wrapper,
                isCollapsed && styles.isCollapsed,
            ].join(' ')}
            padding="none"
        >
            <div className={styles.base}>
                {hasRole(profile, 'Administrator') ? (
                    <Menu>
                        <Menu.Header>
                            Administrator
                        </Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('orders')}
                            className={styles.menuItem}
                            content="Order overview"
                            icon={faFileAlt}
                            href="/orders"
                        />
                        <Menu.Item
                            active={/admin\/(settings|users|products|hospitals)/.test(location.pathname)}
                            className={styles.menuItem}
                            content="Settings"
                            icon={faCog}
                            href="/admin/settings"
                        />
                    </Menu>
                ) : (
                    <Menu>
                        <Menu.Header>
                            My orders
                        </Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('orders/create')}
                            className={styles.menuItem}
                            content="New order"
                            href="/orders/create"
                            icon={faPlus}
                        />
                        <Menu.Item
                            active={location.pathname === '/orders'}
                            className={styles.menuItem}
                            content="Order overview"
                            icon={faFileAlt}
                            href="/orders"
                        />
                    </Menu>
                )}
            </div>
        </Segment>
    );
}

export default Drawer;
