import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from '.';
import { ApiHcp } from './hcp';
import { ApiProduct, ApiProductDosage } from './product';

export interface ApiOrderProduct {
    productId: number,
    product: ApiProduct,
    productDosageId: number,
    productDosage: ApiProductDosage,
    amount: number,
    indication1?: boolean,
    indication2?: boolean,
    indication3?: boolean,
}

export interface ApiOrder {
    id: string,
    number: string,
    hcpId: string,
    hcp?: ApiHcp,
    products?: ApiOrderProduct[],
    hospital: string,
    comment: string,
    address: string,
    zipcode: string,
    city: string,
    status: number,
    statusName: string,
    createdAt: string,
    signedAt?: string,
    needsSignature?: string,
}

const rest = {
    checkLimits: (order: Partial<ApiOrder>): AxiosPromise => {
        return request.post(`orders/check-limits`, order);
    },
    delete: (orderId: string): AxiosPromise => {
        return request.delete(`admin/orders/${orderId}`);
    },
    get: (orderId: string): AxiosPromise<ApiOrder> => {
        return request.get(`orders/${orderId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiOrder[]>> => {
        return request.get(`orders?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (order: Partial<ApiOrder>, hcp: ApiHcp, type?: string): AxiosPromise<ApiOrder> => {
        return request.post(`orders${order.id ? `/${order.id}` : ''}`, { order, hcp, type });
    },
    uploadSignature: (orderId: string, file: File): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
        return request.post(`orders/${orderId}/upload`, data);
    },
}

export default rest;
