import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiHcp {
    id: string,
    name: string,
    registrationNumber: number,
    nationalid: number,
    numberHasChanged: boolean,
    address?: string,
    zipcode?: string,
    city?: string,
}

const rest = {
    get: (userId: number): AxiosPromise<ApiHcp> => {
        return axios.get(`admin/hcps/${userId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiHcp[]>> => {
        return axios.get(`admin/hcps?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    uploadImport: (file: File): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
        return axios.post('admin/hcps/upload-import', data);
    },
}

export default rest;
