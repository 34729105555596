import React, { FC } from 'react';
import TableCell, { TableCellProps } from './Cell';

const TableHeaderCell: FC<TableCellProps> = ({ as, ...props }): JSX.Element => {
    return (
        <TableCell as="th" {...props} />
    );
}

export default TableHeaderCell;
