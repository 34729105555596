import React, { FC, useEffect, useState } from 'react';
import api from '../../api';
import { Button, Modal } from '../../RbKit';

const AlertModal: FC = (): JSX.Element => {
    const [ open, setOpen ] = useState<boolean>(false);
    const [ content, setContent ] = useState<string>('');

    useEffect(() => {
        api.getActiveAlert().then(({ data }) => {
            setContent(data.content || '');
            setOpen(true);
        }).catch(() => setOpen(false));
    }, []);

    const handleClose = (): void => {
        setOpen(false);
    }

    return (
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Close"
                        link
                        onClick={() => handleClose()}
                    />
                </div>
            )}
            header="Attention"
            onClose={() => handleClose()}
            open={open}
            size="small"
        >
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </Modal>
    );
}

export default AlertModal;
