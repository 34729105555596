import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiHospital {
    id: number,
    uci: string,
    name: string,
    address: string,
    zipcode: string,
    city: string,
}

const rest = {
    delete: (hospitalId: number | string): AxiosPromise => {
        return axios.delete(`admin/hospitals/${hospitalId}`);
    },
    get: (hospitalId: number): AxiosPromise<ApiHospital> => {
        return axios.get(`admin/hospitals/${hospitalId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiHospital[]>> => {
        return axios.get(`hospitals?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (hospital: Partial<ApiHospital>): AxiosPromise<ApiHospital> => {
        return axios.post(`admin/hospitals${hospital.id ? `/${hospital.id}` : ''}`, hospital);
    },
    uploadImport: (file: File): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
         return axios.post('admin/hospitals/upload-import', data);
    },
}

export default rest;
