import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface DividerProps extends HTMLAttributes<HTMLHRElement> {
}

const Divider: FC<DividerProps> = ({ className, ...props }): JSX.Element => {
    return (
        <hr
            className={[
                styles.divider,
                className,
            ].join(' ')}
            {...props}
        />
    );
}

export default Divider;
