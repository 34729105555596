import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';

interface LoaderProps {
    card?: boolean,
}

const Loader: FC<LoaderProps> = ({ card }) => {
    return (
        <div className={[
            styles.base,
            card && styles.card,
        ].join(' ')}>
            <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
    );
}

export default Loader;
