import { useSelector } from 'react-redux';
import { ApiProfile } from '../api/auth';
import { AppState } from '../store';

export const hasRole = (profile?: ApiProfile, role: string = ''): boolean => {
    return (profile && profile.role === role) || false;
}

export const useProfile = (): ApiProfile | undefined => {
    return useSelector((state: AppState) => state.auth.profile);
}
