import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Confirm } from '../../RbKit';
import topBarStyles from '../../modules/TopBar/styles.module.scss';
import styles from './styles.module.scss';

interface ChangeHandlerProps {
    changesMade: boolean,
    onCancel?: () => void,
    onSave: () => void,
}

const ChangeHandler: FC<ChangeHandlerProps> = ({ changesMade, onCancel, onSave }): JSX.Element => {
    const [ show, setShow ] = useState<boolean>(changesMade);
    useEffect(() => setShow(changesMade), [changesMade]);

    return (<>
        <Prompt
            when={show}
            message="Are you sure you wish to leave? All unsaved changes will be lost."
        />
        <div
            className={[
                topBarStyles.container,
                styles.container,
                show ? styles.show : '',
            ].join(' ')}
        >
            <div>
                <h1>{process.env.REACT_APP_APPLICATION_NAME}</h1>
            </div>
            <p>
                Unsaved changes
            </p>
            <div className={topBarStyles.account}>
                {onCancel && <Confirm
                    content="Are you sure you wish to cancel? All unsaved changes will be lost."
                    onConfirm={onCancel}
                    trigger={<Button label="Cancel" style={{ marginRight: '1rem' }} />}
                />}
                <Button
                    primary
                    label="Save"
                    onClick={onSave}
                />
            </div>
        </div>
    </>);
}

export default ChangeHandler;
