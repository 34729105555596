import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Form, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiAlert } from '../../../api/alert';
import ChangeHandler from '../../../components/ChangeHandler';
import { Editor } from '@tinymce/tinymce-react';

interface AlertEditProps extends RouteComponentProps<{ id?: string }> {
}

const AlertEditView: FC<AlertEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ alert, setAlert ] = useState<Partial<ApiAlert>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getAlert(parseInt(id)).then(({ data }) => {
                setAlert(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setAlert({
            ...alert,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putAlert(alert).then(() => {
            setIsLoading(false);
            setChangesMade(false);
            toast('Alert saved succesfully');
            history.push('/admin/alerts');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/settings': 'Settings',
                '/admin/alerts': 'Alerts',
                [`/admin/alerts/${id ? `${id}/edit` : 'create'}`]: id ? alert.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} alert`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Group>
                    <Checkbox
                        checked={alert.isActive}
                        label="Is active"
                        onChange={({ checked }: any) => handleInput({ name: 'isActive', value: checked })}
                    />
                </Form.Group>
                <Form.Input
                    error={errors.name}
                    label="Name"
                    name="name"
                    onChange={handleInput}
                    required
                    value={alert.name || ''}
                />
                <Form.Group>
                    <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={alert.content}
                        init={{
                            height: 400,
                            // max_height: 400,
                            menubar: false,
                            plugins: ['code', 'lists', 'link', 'paste'],
                            toolbar: [
                                'pastetext | bold italic underline | subscript superscript | alignleft aligncenter alignright | bullist numlist | link unlink | formatselect ',
                            ],
                            // autoresize_bottom_margin: 16,
                            force_br_newlines: true,
                            paste_as_text: true,
                            block_formats: 'Heading 1=h1;Heading 2=h2;Paragraph=p',
                            extended_valid_elements: 'span,u,i,em,strong,b',
                            relative_urls: false,
                        }}
                        onEditorChange={(c: string) => handleInput({ name: 'content', value: c })}
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default AlertEditView;
