import React, { FC, InputHTMLAttributes, useState } from 'react';
import Input, { InputProps } from '../../elements/Input';
import styles from './styles.module.scss';

interface FormInputProps extends InputProps {
    info?: string,
    label?: string,
}

const FormInput: FC<FormInputProps & InputHTMLAttributes<HTMLInputElement>> = ({
    error,
    info,
    label,
    ...props
}): JSX.Element => {
    const [ focus, setFocus ] = useState<boolean>(false);

    return (
        <div
            className={[
                styles.group,
                (props.value !== undefined && props.value !== '') || focus || props.type === 'file' ? styles.focus : undefined,
                error && styles.hasError,
            ].join(' ')}
        >
            {label && <label className={styles.label}>
                {label}
                {props.required && <span>*</span>}
            </label>}
            <Input
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
                {...props}
            />
            <p className={styles.info}>{error !== true && error !== undefined ? error : info}</p>
        </div>
    )
}

export default FormInput;
