import axios from 'axios';
import { setProfile } from '../reducers/auth';
import store from '../store';
import { ApiHcp } from './hcp';

export interface ApiProfile {
    id: string,
    firstName?: string,
    lastName?: string,
    hcp?: ApiHcp,
    role: string,
}

const rest = {
    getProfile: (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string): Promise<ApiProfile> => {
        return axios.post('auth/login', { emailAddress });
    },
    logout: (): void => {
        localStorage.removeItem('accessToken');
        store.dispatch(setProfile());
        // window.location.href = `${process.env.REACT_APP_API_BASE_URL}_saml.php?logout=1`;
    },
    hcpLogin: (): Promise<any> => {
        return axios.get('auth/saml');
    },
}

export default rest;
