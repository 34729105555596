import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import api from '../../../api';
import { Button, Modal, Segment } from '../../../RbKit';
import styles from './styles.module.scss';

const LoginView: FC = (): JSX.Element => {
    const [ showError, setShowError ] = useState<string>();
    // const [ emailAddress, setEmailAddress ] = useState<string>('');
    // const [ error, setError ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isUser, setIsUser ] = useState<boolean>(false);
    const [ param, setParam ] = useState<string>();
    const history = useHistory();

    useEffect(() => {
        const token = qs.parse(window.location.search.substring(1));
        
		if (token && token._setToken) {
            setIsLoading(true);
            localStorage.setItem('accessToken', token._setToken as string);
            api.getProfile().then(() => {
                history.push('/');
            });
		} else if (/*process.env.NODE_ENV === 'production' && */window.location.pathname !== '/admin' && !token.loggedout) {
            if (!token.checked) {
                window.location.href = `${process.env.REACT_APP_RODIP_URL || ''}?check=1`;
            }

            if (token.err) {
                setShowError(token.err as string);
            }
            if (token.param) {
                setParam(token.param as string);
            }

            setIsUser(true);
        }

        document.querySelector('input')?.focus();
    }, [history]);

    // const login = (): void => {
    //     if (!emailAddress) {
    //         toast('Please fill in an email address', 'error');
    //         setError(true);
    //         return;
    //     }

    //     setError(false);
    //     setIsLoading(true);
        
    //     api.login(emailAddress).then(({ data }: any) => {
    //         setIsLoading(false);
    //         // window.location.href = data.url;
    //         toast('A magic link has been sent');
    //     }).catch(() => {
    //         setError(true);
    //         setIsLoading(false);
    //     });
    // }

    return (<>
        <div className={styles.container}>
            <div>
                {isUser ? (
                    <Segment isLoading={isLoading} elevate={1}>
                        <h2>Login</h2>
                        <p>
                            You are currently not logged in. Click the button below to login with your RochePro account.
                        </p>
                        <div className={styles.btnContainer}>
                            <Button
                                label="Login"
                                onClick={() => window.location.href = `${process.env.REACT_APP_RODIP_URL || ''}`}
                                primary
                            />
                        </div>
                    </Segment>
                ) : (
                    <Segment isLoading={isLoading} elevate={1}>
                        <h2>Login</h2>
                        <p>
                            You are currently not logged in. Click the button below to login with your Roche account.
                        </p>
                        <div className={styles.btnContainer}>
                            <Button
                                label="Login"
                                onClick={() => window.location.href = `${process.env.REACT_APP_RODIP_URL || ''}?admin=1`}
                                primary
                            />
                        </div>
                    </Segment>
                    // <Segment isLoading={isLoading} elevate={1}>
                    //     <h2>Request a login link</h2>
                    //     <p>
                    //         Enter your email address below and we'll send you a magic link to log you in to Application name!
                    //     </p>
                    //     <Form.Input
                    //         error={error}
                    //         label="Email address"
                    //         onChange={({ value }: any) => setEmailAddress(value)}
                    //         value={emailAddress}
                    //     />
                    //     <div className={styles.btnContainer}>
                    //         <Button
                    //             label="Send me the link"
                    //             onClick={() => login()}
                    //             primary
                    //         />
                    //     </div>
                    // </Segment>
                )}
            </div>
        </div>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Ok"
                        link
                        onClick={() => setShowError(undefined)}
                    />
                </div>
            )}
            header="Login failure"
            onClose={() => setShowError(undefined)}
            open={showError !== undefined}
            size="small"
        >
            {showError === '100' && <div>Something went wrong, please try again{param ? ` (id: ${param})` : ''}</div>}
            {showError === '101' && <div>Incomplete RochePro registration{param ? ` (id: ${param})` : ''}</div>}
            {showError === '102' && <div>Login failure: missing RIZIV/INAMI number{param ? ` (id: ${param})` : ''}</div>}
            {showError === '103' && <div>RIZIV/INAMI number not authorized{param ? ` (id: ${param})` : ''}</div>}
        </Modal>
    </>);
}

export default LoginView;
