import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
    content?: string,
}

const Header: FC<HeaderProps> = ({ children, className, content, ...props }): JSX.Element => {
    return (
        <div
            className={[
                styles.header,
                className,
            ].join(' ')}
            {...props}
        >
            {children || content}
        </div>
    );
}

export default Header;
