import { faBullhorn, faBox, faBuilding, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../api';
import { Form, Grid, PageHeader, Segment } from '../../../RbKit';
import styles from './styles.module.scss';

const SettingsView: FC = (): JSX.Element => {
    const [ settings, setSettings ] = useState<any>({ notification: '' });

    useEffect(() => {
        request.get('admin/settings').then(({ data }) => {
            setSettings(data);
        });
    }, []);

    const updateSettings = (interval: string): void => {
        setSettings({ notification: interval });
        request.post('admin/update-settings', { interval });
    }

    return (<>
        <PageHeader title="Settings" breadcrumb={{'/admin/settings': 'Settings'}} />
        <Grid.Row>
            <Grid.Column md={4}>
                <Segment padding="dense" className={styles.link}>
                    <Link to="/admin/products">
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={faBox} />
                        </div>
                        <div className={styles.info}>
                            <h3>Products</h3>
                            <p>Add, alter or delete products</p>
                        </div>
                    </Link>
                </Segment>
            </Grid.Column>
            <Grid.Column md={4}>
                <Segment padding="dense" className={styles.link}>
                    <Link to="/admin/hospitals">
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={faBuilding} />
                        </div>
                        <div className={styles.info}>
                            <h3>Hospitals</h3>
                            <p>Add, alter or delete hospitals</p>
                        </div>
                    </Link>
                </Segment>
            </Grid.Column>
            <Grid.Column md={4}>
                <Segment padding="dense" className={styles.link}>
                    <Link to="/admin/users">
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <div className={styles.info}>
                            <h3>Users</h3>
                            <p>Add, alter or delete users</p>
                        </div>
                    </Link>
                </Segment>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column md={4}>
                <Segment padding="dense" className={styles.link}>
                    <Link to="/admin/hcps">
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <div className={styles.info}>
                            <h3>HCPs</h3>
                            <p>Import and update HCPs</p>
                        </div>
                    </Link>
                </Segment>
            </Grid.Column>
            <Grid.Column md={4}>
                <Segment padding="dense" className={styles.link}>
                    <Link to="/admin/alerts">
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={faBullhorn} />
                        </div>
                        <div className={styles.info}>
                            <h3>Alerts</h3>
                            <p>Show users an alert at login</p>
                        </div>
                    </Link>
                </Segment>
            </Grid.Column>
        </Grid.Row>

        <div style={{ width: 250 }}>
            <Form.Dropdown
                label="Notification settings"
                onChange={({ value }: any) => updateSettings(value)}
                options={[{
                    text: 'Send immediately',
                    value: 'immediately',
                }, {
                    text: 'Send weekly',
                    value: 'weekly',
                }, {
                    text: 'Send monthly',
                    value: 'monthly',
                }]}
                value={settings.notification}
            />
        </div>
    </>);
}

export default SettingsView;
