import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';
import { ApiHcp } from './hcp';

export interface ApiUser {
    id: string,
    firstName?: string,
    lastName?: string,
    hcp?: ApiHcp,
    emailAddress: string,
    role: string,
}

const rest = {
    delete: (userId: string): AxiosPromise => {
        return axios.delete(`admin/users/${userId}`);
    },
    get: (userId: string): AxiosPromise<ApiUser> => {
        return axios.get(`admin/users/${userId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiUser[]>> => {
        return axios.get(`admin/users?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (user: Partial<ApiUser>): AxiosPromise<ApiUser> => {
        return axios.post(`admin/users${user.id ? `/${user.id}` : ''}`, user);
    },
}

export default rest;
