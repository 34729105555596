import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface RowProps extends HTMLAttributes<HTMLDivElement> {
    center?: boolean,
    noGutters?: boolean,
}

const Row: FC<RowProps> = ({ center, children, noGutters, ...props }): JSX.Element => {
    return (
        <div
            className={[
                styles.row,
                noGutters ? styles.noGutters : '',
                center ? styles.center : '',
            ].join(' ')}
            {...props}
        >
            {children}
        </div>
    );
}

export default Row;
