import React, { FC } from 'react';
import { PaddingType } from '../../definitions';
import styles from './styles.module.scss';

interface TagProps {
    label?: string,
    error?: boolean,
    info?: boolean,
    padding?: PaddingType,
    success?: boolean,
    warning?: boolean,
}

const Tag: FC<TagProps> = ({ children, error, info, label, padding, success, warning }): JSX.Element => {
    return (
        <div
            className={[
                styles.base,
                padding ? styles[`${padding}Padding`] : '',
                error ? styles.error : '',
                info ? styles.info : '',
                success ? styles.success : '',
                warning ? styles.warning : '',
            ].join(' ')}
        >
            {label || children}
        </div>
    );
}

export default Tag;
